/* eslint-disable no-unused-vars */
import {
  getContrastRatio,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material';
import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions,
} from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import localFont from 'next/font/local';
import rem from 'polished/lib/helpers/rem';

export const outfit = localFont({
  src: [{ path: './Fonts/Specimen/Outfit/Outfit-VariableFont_wght.woff2' }],
});

export const inter = localFont({
  src: [{ path: './Fonts/Specimen/Inter/Inter-VariableFont_opsz,wght.woff2' }],
});

export type Typo =
  | 'title2Xl'
  | 'titleXl'
  | 'titleLg'
  | 'titleMd'
  | 'titleSm'
  | 'titleXs'
  | 'title2Xs'
  | 'body2xl'
  | 'bodyXl'
  | 'bodyXlBold'
  | 'bodyLg'
  | 'bodyLgBold'
  | 'bodyMd'
  | 'bodyMdBold'
  | 'bodySm'
  | 'bodySmBold';

// THEME ASSOCIE A CE DS : https://www.figma.com/file/lva0oVRwCQcM4kV1wc1nny/%F0%9F%8E%92-Backpack---Foundations?node-id=1%3A10

export const PENDING_GRADIENT_COLOR =
  'linear-gradient(90deg, #2563EB 0%, #38BDF8 34.9%, #50C686 68.23%, #6366F1 100%)';

export const BG_GRADIENT_COLOR =
  'linear-gradient(92deg, rgb(219, 234, 254), rgb(239, 246, 255))';

declare module '@mui/material/styles' {
  interface CustomShadow {
    low: string;
    medium: string;
    high: string;
  }

  interface CustomShadowOptions {
    low?: string;
    medium?: string;
    high?: string;
  }

  interface CustomBorder {
    main: string;
    '700': string;
    error: string;
  }

  interface CustomBorderOptions {
    main?: string;
    '700'?: string;
    error?: string;
  }

  interface Theme {
    shadow: CustomShadow;
    border: CustomBorder;
  }

  interface ThemeOptions {
    shadow: CustomShadowOptions;
    border: CustomBorderOptions;
  }

  interface CustomPaletteOptions {
    red?: PaletteColorOptions;
    amber?: PaletteColorOptions;
    blue?: PaletteColorOptions;
    rose?: PaletteColorOptions;
    sky?: PaletteColorOptions;
    indigo?: PaletteColorOptions;
    green?: PaletteColorOptions;
  }

  interface CustomPaletteColor {
    red: PaletteColor;
    amber: PaletteColor;
    blue: PaletteColor;
    rose: PaletteColor;
    sky: PaletteColor;
    indigo: PaletteColor;
    green: PaletteColor;
  }

  interface PaletteOptions {
    supporting?: CustomPaletteOptions;
    neutral?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
    quaternary?: PaletteColorOptions;
  }
  interface Palette {
    supporting: CustomPaletteColor;
    neutral: PaletteColor;
    tertiary?: PaletteColor;
    quaternary?: PaletteColor;
  }

  interface CustomTypoContentOptions {
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: number;
    lineHeight?: string;
    color?: string;
    letterSpacing?: string;
  }

  interface CustomTypoContent {
    fontFamily: string;
    fontSize: string;
    fontWeight: number;
    lineHeight: string;
    color: string;
    letterSpacing: string;
  }

  interface TypographyVariants {
    title2Xl: CustomTypoContent;
    titleXl: CustomTypoContent;
    titleLg: CustomTypoContent;
    titleMd: CustomTypoContent;
    titleSm: CustomTypoContent;
    titleXs: CustomTypoContent;
    title2Xs: CustomTypoContent;
    body2xl: CustomTypoContent;
    bodyXl: CustomTypoContent;
    bodyXlBold: CustomTypoContent;
    bodyLg: CustomTypoContent;
    bodyLgBold: CustomTypoContent;
    bodyMd: CustomTypoContent;
    bodyMdBold: CustomTypoContent;
    bodySm: CustomTypoContent;
    bodySmBold: CustomTypoContent;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title2Xl: CustomTypoContentOptions;
    titleXl: CustomTypoContentOptions;
    titleLg: CustomTypoContentOptions;
    titleMd: CustomTypoContentOptions;
    titleSm: CustomTypoContentOptions;
    titleXs: CustomTypoContentOptions;
    title2Xs: CustomTypoContentOptions;
    body2xl: CustomTypoContentOptions;
    bodyXl: CustomTypoContentOptions;
    bodyXlBold: CustomTypoContentOptions;
    bodyLg: CustomTypoContentOptions;
    bodyLgBold: CustomTypoContentOptions;
    bodyMd: CustomTypoContentOptions;
    bodyMdBold: CustomTypoContentOptions;
    bodySm: CustomTypoContentOptions;
    bodySmBold: CustomTypoContentOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title2Xl: true;
    titleXl: true;
    titleLg: true;
    titleMd: true;
    titleSm: true;
    titleXs: true;
    title2Xs: true;
    body2xl: true;
    bodyXl: true;
    bodyXlBold: true;
    bodyLg: true;
    bodyLgBold: true;
    bodyMd: true;
    bodyMdBold: true;
    bodySm: true;
    bodySmBold: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    quaternary: true;
  }
  interface ButtonPropsVariantOverrides {
    tertiary: true;
    quaternary: true;
  }
  interface ButtonPropsClassesOverrides {
    containedTertiary: true;
    outlinedTertiary: true;
    containedQuaternary: true;
  }
}

// Create a theme instance.
const palette: PaletteOptions = {
  primary: {
    dark: '#1D4730',
    200: '#317951',
    main: '#3E9A67',
    400: '#48B378',
    500: '#50C686',
    600: '#7FD5A6',
    700: '#A2E1BE',
    800: '#C0EAD3',
    900: '#DBF3E6',
    light: '#F3FBF7',
  },
  neutral: {
    dark: '#18181B',
    200: '#27272A',
    300: '#3F3F46',
    main: '#52525B',
    500: '#71717A',
    600: '#A1A1AA',
    700: '#D4D4D8',
    800: '#E4E4E7',
    900: '#F4F4F5',
    light: '#FAFAFA',
  },
  supporting: {
    red: {
      dark: '#7F1D1D',
      200: '#991B1B',
      300: '#B91C1C',
      main: '#DC2626',
      500: '#EF4444',
      600: '#F87171',
      700: '#FCA5A5',
      800: '#FECACA',
      900: '#FEE2E2',
      light: '#FEF2F2',
    },
    amber: {
      dark: '#78350F',
      200: '#92400E',
      300: '#B45309',
      main: '#D97706',
      500: '#F59E0B',
      600: '#FBBF24',
      700: '#FCD34D',
      800: '#FDE68A',
      900: '#FEF3C7',
      light: '#FFFBEB',
    },
    blue: {
      dark: '#1E3A8A',
      200: '#1E40AF',
      300: '#1D4ED8',
      main: '#2563EB',
      500: '#3B82F6',
      600: '#60A5FA',
      700: '#93C5FD',
      800: '#BFDBFE',
      900: '#DBEAFE',
      light: '#EFF6FF',
    },
    rose: {
      dark: '#881337',
      200: '#9F1239',
      300: '#BE123C',
      main: '#E11D48',
      500: '#F43F5E',
      600: '#FB7185',
      700: '#FDA4AF',
      800: '#FECDD3',
      900: '#FFE4E6',
      light: '#FFF1F2',
    },
    indigo: {
      dark: '#312E81',
      200: '#3730A3',
      300: '#4338CA',
      main: '#4F46E5',
      500: '#6366F1',
      600: '#818CF8',
      700: '#A5B4FC',
      800: '#A5B4FC',
      900: '#E0E7FF',
      light: '#EEF2FF',
    },
    sky: {
      dark: '#0C4A6E',
      200: '#075985',
      300: '#0369A1',
      main: '#0284C7',
      500: '#0EA5E9',
      600: '#38BDF8',
      700: '#7DD3FC',
      800: '#BAE6FD',
      900: '#E0F2FE',
      light: '#F0F9FF',
    },
    green: {
      dark: '#14532D',
      200: '#166534',
      300: '#15803D',
      main: '#16A34A',
      500: '#22C55E',
      600: '#4ADE80',
      700: '#86EFAC',
      800: '#BBF7D0',
      900: '#DCFCE7',
      light: '#F0FDF4',
    },
  },
  secondary: {
    main: '#39415f',
  },
  tertiary: {
    main: '#fff',
    light: '#000',
    dark: '#fff',
    contrastText: getContrastRatio('#000', '#fff') > 4.5 ? '#fff' : '#111',
  },
  quaternary: {
    main: '#27272A',
  },
  error: {
    main: '#FF0000',
  },
  background: {
    default: '#fbfcfc',
  },
  success: {
    main: '#39415f',
  },
  text: {
    primary: '#18181B',
    secondary: '#71717A',
  },
};

const breakpoints = createBreakpoints({
  values: {
    xs: 320,
    sm: 720,
    md: 960,
    lg: 1024,
    xl: 1920,
  },
});

export const COMMON_OPTIONS: ThemeOptions = {
  breakpoints,
  palette,
  shadow: {
    low: '0px 2px 4px rgba(24, 24, 27, 0.02)',
    medium: '0px 4px 8px rgba(24, 24, 27, 0.04)',
    high: '0px 8px 12px -4px rgba(24, 24, 27, 0.08)',
  },
  border: {
    main: '1px solid #E4E4E7',
    error: '1px solid #DC2626',
    700: '1px solid #D4D4D8',
  },
  typography: {
    fontFamily: outfit.style.fontFamily,
    button: {
      textTransform: 'none',
    },
    title2Xl: {
      fontFamily: outfit.style.fontFamily,
      fontWeight: 600,
      color: (palette.neutral as SimplePaletteColorOptions).dark, // Note that this was needed as the type checking does not like the direct access here
      letterSpacing: rem(-0.64),
      fontSize: rem(32),
      lineHeight: rem(44),
      [breakpoints.up('md')]: {
        fontSize: rem(48),
        lineHeight: rem(60),
        letterSpacing: rem(-0.96),
      },
    },
    titleXl: {
      fontFamily: outfit.style.fontFamily,
      fontWeight: 600,
      color: (palette.neutral as SimplePaletteColorOptions).dark,
      letterSpacing: rem(-0.56),
      fontSize: rem(28),
      lineHeight: rem(40),
      [breakpoints.up('md')]: {
        fontSize: rem(40),
        lineHeight: rem(52),
        letterSpacing: rem(-0.8),
      },
    },
    titleLg: {
      fontFamily: outfit.style.fontFamily,
      fontWeight: 600,
      color: (palette.neutral as SimplePaletteColorOptions).dark,
      letterSpacing: rem(-0.48),
      fontSize: rem(24),
      lineHeight: rem(36),
      [breakpoints.up('md')]: {
        fontSize: rem(36),
        lineHeight: rem(48),
        letterSpacing: rem(-0.72),
      },
    },
    titleMd: {
      fontFamily: outfit.style.fontFamily,
      fontWeight: 600,
      color: (palette.neutral as SimplePaletteColorOptions).dark,
      letterSpacing: rem(-0.48),
      fontSize: rem(24),
      lineHeight: rem(36),
      [breakpoints.up('md')]: {
        fontSize: rem(32),
        lineHeight: rem(44),
        letterSpacing: rem(-0.64),
      },
    },
    titleSm: {
      fontFamily: outfit.style.fontFamily,
      fontWeight: 600,
      color: (palette.neutral as SimplePaletteColorOptions).dark,
      letterSpacing: rem(-0.48),
      fontSize: rem(24),
      lineHeight: rem(36),
      [breakpoints.up('md')]: {
        fontSize: rem(28),
        lineHeight: rem(40),
        letterSpacing: rem(-0.56),
      },
    },
    titleXs: {
      fontFamily: outfit.style.fontFamily,
      fontWeight: 600,
      color: (palette.neutral as SimplePaletteColorOptions).dark,
      letterSpacing: rem(-0.4),
      fontSize: rem(20),
      lineHeight: rem(32),
      [breakpoints.up('md')]: {
        fontSize: rem(24),
        lineHeight: rem(36),
        letterSpacing: rem(-0.48),
      },
    },
    title2Xs: {
      fontFamily: outfit.style.fontFamily,
      fontWeight: 600,
      color: (palette.neutral as SimplePaletteColorOptions).dark,
      letterSpacing: rem(-0.4),
      fontSize: rem(20),
      lineHeight: rem(32),
      [breakpoints.up('md')]: {
        fontSize: rem(20),
        lineHeight: rem(32),
        letterSpacing: rem(-0.4),
      },
    },
    body2xl: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 400,
      color: palette.neutral[200],
      letterSpacing: rem(-0.64),
      fontSize: rem(16),
      lineHeight: rem(24),
      [breakpoints.up('md')]: {
        fontSize: rem(24),
        lineHeight: rem(36),
        letterSpacing: rem(-0.96),
      },
    },
    bodyXl: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 400,
      color: palette.neutral[200],
      letterSpacing: rem(-0.64),
      fontSize: rem(16),
      lineHeight: rem(24),
      [breakpoints.up('md')]: {
        fontSize: rem(20),
        lineHeight: rem(28),
        letterSpacing: rem(-0.8),
      },
    },
    bodyXlBold: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 500,
      color: palette.neutral[200],
      letterSpacing: rem(-0.64),
      fontSize: rem(16),
      lineHeight: rem(24),
      [breakpoints.up('md')]: {
        fontSize: rem(20),
        lineHeight: rem(28),
        letterSpacing: rem(-0.8),
      },
    },
    bodyLg: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 400,
      color: palette.neutral[200],
      letterSpacing: rem(-0.64),
      fontSize: rem(16),
      lineHeight: rem(24),
    },
    bodyLgBold: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 500,
      color: palette.neutral[200],
      letterSpacing: rem(-0.64),
      fontSize: rem(16),
      lineHeight: rem(24),
    },
    bodyMd: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 400,
      color: palette.neutral[200],
      letterSpacing: rem(-0.56),
      fontSize: rem(14),
      lineHeight: rem(20),
    },
    bodyMdBold: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 500,
      color: palette.neutral[200],
      letterSpacing: rem(-0.56),
      fontSize: rem(14),
      lineHeight: rem(20),
    },
    bodySm: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 400,
      color: palette.neutral[500],
      letterSpacing: rem(-0.6),
      fontSize: rem(12),
      lineHeight: rem(20),
    },
    bodySmBold: {
      fontFamily: inter.style.fontFamily,
      fontWeight: 500,
      color: palette.neutral[500],
      letterSpacing: rem(-0.6),
      fontSize: rem(12),
      lineHeight: rem(20),
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        // TODO: make custom props here ?
      },
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        text: {
          fontSize: '17px',
        },
      },
    },
  },
};

export const ssrBreakpoint = {
  mobile: '0px',
  desktop: '1024px',
};

const themeFoundation = createTheme(COMMON_OPTIONS);

export const GRADIENT_1 = `linear-gradient(59.32deg, ${themeFoundation.palette.supporting.green[500]} 0%, ${themeFoundation.palette.supporting.sky[600]} 32.29%, ${themeFoundation.palette.supporting.rose[600]} 66.67%, ${themeFoundation.palette.supporting.amber[600]} 100%)`;
export const GRADIENT_3 = `linear-gradient(90deg, ${themeFoundation.palette.supporting.rose[600]} 0%, ${themeFoundation.palette.supporting.indigo[500]} 32.29%, ${themeFoundation.palette.supporting.sky[600]} 66.67%, ${themeFoundation.palette.supporting.blue[500]} 100%)`;
export const GRADIENT_SEARCH_HEADER = `linear-gradient(to bottom, transparent 0%, #fff 100%), linear-gradient(90deg, rgba(251, 113, 133, 0.16) 0%, rgba(99, 102, 241, 0.16) 31.25%, rgba(56, 189, 248, 0.16) 66.67%, rgba(59, 130, 246, 0.16) 100%)`;
export const GRADIENT_LANDING = `radial-gradient(94.97% 94.97% at 50% -18.64%, #35A66D 0%, #81C0A0 31.5%, rgba(156, 235, 195, 0.14) 64.87%), radial-gradient(112.08% 145.99% at 96.42% -2.93%, rgba(19, 234, 146, 0.8) 0%, rgba(40, 237, 155, 0.8) 21.02%, rgba(109, 240, 186, 0.656) 41.87%, rgba(242, 242, 242, 0) 73.69%, rgba(255, 255, 255, 0) 98.48%), radial-gradient(89.94% 124.23% at 7.16% 0%, #65E269 0%, #91EB93 18.47%, rgba(170, 240, 171, 0.595004) 39.88%, rgba(202, 255, 204, 0) 61.85%), linear-gradient(180deg, rgba(255, 255, 255, 0) 49.94%, #FFFFFF 100%)`;
export const BOX_SHADOW_PRIMARY = `0px 0px 0px 4px rgba(80, 198, 134, 0.12)`;
export const BOX_SHADOW_HIGH = '0px 8px 12px -4px rgba(24, 24, 27, 0.08)';

export const errorColor = themeFoundation.palette.supporting.red.main;
export const NAV_BAR_MIN_HEIGHT = 76;

export default themeFoundation;
